<template>
  <div>
    <div class="header">
      <div class="header_top">
        <span style="margin: 0">当前位置：</span>
        <span class="center">合同管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">待审核合同列表</span>
      </div>
    </div>
    <div class="top">
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <div>
          <span class="seach_text">关键字：</span>

          <el-input
            size="mini"
            placeholder="请输入合同名称"
            v-model="formInline.f_like_name"
            style="margin-right: 10px; width: 250px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div>
          <span class="seach_text">合同分类：</span>
          <el-select
            size="mini"
            v-model="formInline.type"
            style="margin-right: 10px; width: 250px"
            placeholder="请选择合同分类"
            clearable
            @change="loadData('seath')"
          >
            <el-option label="全部" value></el-option>
            <el-option label="入职合同" value="1"></el-option>
            <el-option label="离职合同" value="2"></el-option>
            <el-option label="其他" value="3"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">用工企业名称：</span>
          <el-select
            remote
            reserve-keyword
            :remote-method="query"
            @change="getCity"
            v-model="formInline.workEnterpriseCode"
            clearable
            filterable
            placeholder="请输入企业名称"
            size="mini"
            style="margin-right: 10px"
          >
            <el-option v-for="item in eList" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">签署人：</span>

          <el-input
            size="mini"
            placeholder="请输入签署人"
            v-model="formInline.signerName"
            style="margin-right: 10px; width: 200px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div>
          <span class="seach_text">企业区域：</span>
          <el-select
            v-model="formInline.workEnterpriseAreaCode"
            style="margin-right: 10px; width: 200px"
            filterable
            clearable
            size="mini"
            :placeholder="'请输入企业区域'"
          >
            <el-option-group
              v-for="group in areaSelectList"
              :key="group.provinceName"
              :label="group.provinceName"
            >
              <span style="color: red" slot="label">
                {{
                group.provinceName
                }}
              </span>
              <el-option
                v-for="item in group.citys"
                :key="item.code"
                :label="item.cityName"
                :value="item.code"
              ></el-option>
            </el-option-group>
          </el-select>
        </div>

        <el-button
          size="small"
          style="margin: 10px; border-radius: 5px"
          type="primary"
          @click="seach"
        >查询</el-button>
      </div>
      <div>
        <el-popconfirm
          confirm-button-text="确定"
          cancel-button-text="取消"
          icon="el-icon-circle-check"
          icon-color="#67C23A"
          :title="`是否通过《${signFlowStatusName}》合同通过审核`"
          @confirm="batchSigning"
        >
          <el-button
            size="small"
            slot="reference"
            :disabled="signFlowStatus.length===0"
            icon="el-icon-plus"
            type="primary"
          >批量通过</el-button>
        </el-popconfirm>
      </div>
    </div>
    <el-table :data="list" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="合同名称" width="250">
        <template slot-scope="scope">
          <div class="clickColor" @click="goDetail({ code: scope.row.code })">{{ scope.row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="签署主体" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.signers" :key="index">
            <span>{{ index | subjectTypeVal }}：</span>
            <span>{{ item.subjectName || '未设置' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="签署人">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.signers" :key="index">
            <div>
              <span>{{ item.signerName || '未设置' }}</span>
              <i
                class="iconfont"
                :class="{ iconclock: scope.row.signersState[index] === '100'||  scope.row.signersState[index] === '101'||scope.row.signersState[index] === '103',
                          iconsuccess: scope.row.signersState[index] === '2',
                          iconreeor: scope.row.signersState[index] === '102'|| scope.row.signersState[index] === '104'||scope.row.signersState[index] === '7'
                          }"
              ></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="合同分类">
        <template slot-scope="scope">
          <span v-if=" scope.row.type==='1'">入职合同</span>
          <span v-if=" scope.row.type==='2'">离职合同</span>
          <span v-if=" scope.row.type==='3'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="签署状态">
        <template slot-scope="scope">
          <span v-if="scope.row.signFlowStatus==='2'">签署完成</span>
          <span v-if="scope.row.signFlowStatus==='100'">草稿</span>
          <span v-if="scope.row.signFlowStatus==='101'">签署中</span>
          <span v-if="scope.row.signFlowStatus==='103'">已完成</span>
          <span v-if="scope.row.signFlowStatus==='104'">审核不通过</span>
          <span v-if="scope.row.signFlowStatus==='105'">待企业签署</span>
          <span v-if="scope.row.signFlowStatus==='3'">失败</span>
          <span v-if="scope.row.signFlowStatus==='4'">拒签</span>
        </template>
      </el-table-column>

      <el-table-column label="合作企业">
        <template slot-scope="scope">
          <span v-if=" scope.row.workEnterprise">{{scope.row.workEnterprise.name}}</span>

          <span v-else>暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="企业区域">
        <template slot-scope="scope">
          <p v-if="scope.row.area">{{scope.row.area.provinceName}}-{{scope.row.area.cityName }}</p>
          <p v-else>暂无</p>
          <span v-if="scope.row.store">{{ scope.row.store.name }}</span>
          <span v-else>暂无</span>
        </template>
      </el-table-column>

      <el-table-column label="发起日期">
        <template slot-scope="scope">
          {{
          scope.row.createTime | dateVal
          }}
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="shuxian">
            <el-button @click="goDetail({ code: scope.row.code })" type="text">详情</el-button>

            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="shuxian">
            <el-button @click="goSee({ code: scope.row })" type="text">预览</el-button>

            <el-divider direction="vertical"></el-divider>
          </div>

          <div class="shuxian">
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              :title="`是否通过《${scope.row.name}》合同通过审核`"
              @confirm="confirmButton(scope.row)"
            >
              <el-button type="text" slot="reference">通过</el-button>
            </el-popconfirm>

            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="shuxian">
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              :title="`是否拒绝《${scope.row.name}》合同通过审核`"
              @confirm="refuseButton(scope.row)"
            >
              <el-button type="text" style="color: #f56c6c" slot="reference">拒绝</el-button>
            </el-popconfirm>

            <el-divider direction="vertical"></el-divider>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

//接口：
import { api } from '/src/api/base'
//获取签署列表

export const contract2Guidang = api()('contract2.guidang.json')

export const workEnterpriseList = api()('work_enterprise.list.json')
export const workEnterpriseAreaContainArea = api()(
  'work_enterprise_area.containArea.json'
)
//查询列表接口
export const ContractList = api('/contract')('contract2.list.json')
//查询详情接口


export default {
  data () {
    return {
      getSignUrlLoading: false,
      startContractLoading: false,
      batchSigningShow: false,
      value1: [
        moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD'),
        moment(new Date()).format('YYYY-MM-DD'),
      ],
      gridData: [],
      selectList: [],
      eList: [],
      areaSelectList: [],
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        f_like_name: '',
        signerName: '',
        type: '',
        workEnterpriseAreaCode: '',
        workEnterpriseCode: ''
      },
      list: [],

      isEnterpriseAdmin: null,
      signFlowStatus: [],
      signFlowStatusName: '',


    }
  },

  created () {
    this.isEnterpriseAdmin =
      window.localStorage.getItem('isEnterpriseAdmin')
    this.enterpriseList()
    this.loadData()
  },
  methods: {
    batchSigning () {

      const params = {
        codes: this.signFlowStatus,
        signFlowStatus: '103'
      };
      contract2Guidang(params).then(res => {
        console.log(res);
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.pageNumber = 1
        setTimeout(() => {
          loading.close();
          this.loadData()
        }, 2000);


      });
    },
    handleSelectionChange (val) {
      this.signFlowStatus = val.map(it => it.code).join(',')
      this.signFlowStatusName = val.map(it => it.name).join(',')
      console.log(this.signFlowStatus)
    },
    enterpriseList () {
      const params = {

      }
      workEnterpriseList(params).then((res) => {
        this.eList = res.list
      })
    },
    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res

        });
      }
      else {
        this.areaSelectList = []
        this.formInline.city = ''
      }
    },
    confirmButton (val) {
      const params = {
        codes: val.code,
        signFlowStatus: '103'
      };
      contract2Guidang(params).then(res => {
        console.log(res);
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
          this.pageNumber = 1
          this.enterpriseList()
          this.loadData()
        }, 2000)


      });
    },
    refuseButton (val) {
      const params = {
        codes: val.code,

        signFlowStatus: '104'
      };
      contract2Guidang(params).then(res => {
        console.log(res);

        this.pageNumber = 1
        this.enterpriseList()
        this.loadData()
      });
    },
    goSee (row) {
      window.open(row.code.filePaths)
      console.log('预览', row)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange (val) {
      this.pageNumber = val
      this.loadData()
    },
    loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        signFlowStatus: '2',

      }
      ContractList(params).then((res) => {
        // if (res.list.length != 0) {
        this.list = res.list.map((it) => {

          if (new Set(it.signer.map(m => m.psnId)).size === 2) {
            var a = it.signer.filter(i => i.orgId != '')
            var b = it.signer.filter(i => i.orgId === '')

            return {
              ...it,
              getSignUrlLoading: false,
              startContractLoading: false,
              signers: [{ subjectName: a.length ? a[0].orgName : '', signerName: a.length ? a[0].psnName : '' }, { subjectName: b.length ? b[0].psnName : '', signerName: b.length ? b[0].psnName : '' }],
              signersState: [a.length ? a[0].signResult : '', a.length ? b[0].signResult : ''],

            }

          } else {
            return {
              ...it,
              getSignUrlLoading: false,
              startContractLoading: false
            }
          }


        })

        console.log(this.list);
        this.total = res.total
      })
    },

    goDetail (obj) {
      this.$router.push({
        path: '/system/contract/detail',
        query: obj,
      })
    },
    seach () {
      this.pageNumber = 1
      this.loadData()
    },

    query (val) {
      this.pageNumber = 1
      this.enterpriseList(val)
    }
  },
}
</script>

<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.iconsuccess {
  color: #67c23a;
}

.iconreeor {
  color: #f56c6c;
}

.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}

.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}

.table_border {
  margin: 20px 0;

  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }

  .table_demo {
    padding: 35px 0;
    font-size: 10px;
  }
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
</style>
